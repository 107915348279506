import React, {useEffect, useState} from "react";
import { useAuthFetch }  from './useAuthFetch';
import { observer, useLocalObservable } from 'mobx-react';
import { toJS } from 'mobx';
import { getApiUrl } from "./authConfig";

import TextField from "@mui/material/TextField";
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import "./assets/css/modal.css";


export type CreateAssetDialogData = {
    id?: string,
    assetLocation: string, 
    assetType: string,
    status: string, 
    se04Required: string,
    model: string,
    manufacturer: string,
    productCode: string,
    productFamily: string,
    serialNumber: string,
    commSerialNumber: string,
    defaultTemplate: string
}

const statuses = ["Not Commissioned", "Decommissioned", "Pass", "Fail"];
const assetLocations = ["Warehouse", "Vendor", "Contractor", "Building"]; 
const assetTypes = ["Water", "Gas", "Electricity", "Thermal", "Steam", "Communication"];
const se04RequiredItems = ["No Commissioning", "Commissioning", "SE-04"];

export type CreateAssetDialogProps = {
    isOpen?: boolean
    onSubmit?: (status: string)=>void
    initialData?: CreateAssetDialogData
    buildingId?: string
    productList?: Array<any>
    productFamilies?: Array<any>
    defaultTemplates?: Array<any>
} 

export const CreateAssetDialog = observer((props: CreateAssetDialogProps)=> {
    const authFetch = useAuthFetch(); 
    const [modalOpen, setModalOpen] = useState(false);
    const [modalSuccessOpen, setModalSuccessOpen] = useState(false);

    let isOpen = props.isOpen || false;
    let existingId = props.initialData == null? "": props.initialData.id;
    let buildingId = props.buildingId == null?  "": props.buildingId;

    var productList = props.productList || []; 
    var productFamilies = props.productFamilies || [];
    var defaultTemplates = props.defaultTemplates || [];

    productFamilies = productFamilies.filter(it => {
        let productIdx = productList.findIndex(prd => prd.productFamily == it.id);
        if(productIdx  == -1) {
            return false;
        }
        return true; 
    })

    productList = productList.sort((a, b) => a.productFamily.localeCompare(b.productFamily));

    const handleModalSuccessClose = () => { setModalSuccessOpen(false); handleClose() };
    const handleModalErrorClose = () => { setModalOpen(false); };

    var initialData = props.initialData || {
        assetLocation: "Warehouse", 
        assetType: "Water", 
        status: "Not Commissioned", 
        se04Required: "False", 
        model: "",
        manufacturer: "", 
        productCode: "",
        productFamily: "",
        serialNumber: "",
        commSerialNumber: "",
        defaultTemplate: ""
    }

    const formData = useLocalObservable(()=> ({ 
        assetLocation: "Warehouse", 
        assetType: "Water", 
        status: "Not Commissioned", 
        se04Required: "False", 
        model: "",
        manufacturer: "", 
        productCode: "",
        productFamily: "",
        serialNumber: "",
        commSerialNumber: "",
        defaultTemplate: ""
    }))

    const setInitialData = ()=> {
        formData.assetLocation =  initialData.assetLocation;
        formData.assetType =  initialData.assetType;
        formData.status =  initialData.status;
        formData.se04Required =  initialData.se04Required; 
        formData.model =  initialData.model;
        formData.manufacturer =  initialData.manufacturer;
        formData.productCode = initialData.productCode;
        formData.productFamily = initialData.productFamily;
        formData.serialNumber = initialData.serialNumber;
        formData.commSerialNumber = initialData.commSerialNumber;
        formData.defaultTemplate = initialData.defaultTemplate;

        if(initialData.productCode == "" && productList.length != 0 ) {
            let prd = productList[0]; 
            formData.assetType =  prd.assetType;
            formData.se04Required =  prd.se04Required; 
            formData.model =  prd.model;
            formData.manufacturer =  prd.manufacturer;
            formData.productCode = prd.productCode;
            formData.productFamily = prd.productFamily;
            formData.defaultTemplate = prd.defaultTemplate;
        }
    }

    useEffect(()=> {setInitialData()}, [props.initialData]);

    const formError = useLocalObservable(()=> ({
        model: "",
        manufacturer: "", 
        serialNumber: "",
        commSerialNumber: ""
        
    }))

    const clearErrors = ()=> {
        formError.model = ""; 
        formError.manufacturer = ""; 
        formError.serialNumber = "";
        formError.commSerialNumber= "";
    }

    const handleClose = () => {
        if(props.onSubmit != null) {
            props.onSubmit("cancel")
        }
    };

    const handleSubmit = async ()=> {

        let hasError = false;
        clearErrors();

        if(formData.model == "") {
            hasError = true; 
            formError.model = "Asset model is required";  
        }

        if(formData.manufacturer == "") {
            hasError = true; 
            formError.manufacturer = "Asset manufacturer is required";  
        }

        if(hasError) {
            return;
        }

        let data = toJS(formData);

        let method = existingId == ""? "POST": "PUT"; 
        let url = getApiUrl("assets"); 

        if(existingId != "") {
            url = `${url}/${existingId}`
        }

        let fetchResponse = await authFetch(method, url, {...data, buildingId: buildingId})

        if(fetchResponse.status != 409) {
            if(props.onSubmit != null) {
                props.onSubmit("success")
            }
        } else {
            setModalOpen(true)
        }
    } 

    const handleSubmitPlus = async () => {

       let hasError = false;
        clearErrors();

        if (formData.model == "") {
            hasError = true;
            formError.model = "Asset model is required";
        }

        if (formData.manufacturer == "") {
            hasError = true;
            formError.manufacturer = "Asset manufacturer is required";
        }

        if (hasError) {
            return;
        }

        let data = toJS(formData);


        let method = existingId == "" ? "POST" : "PUT";
        let url = getApiUrl("assets");

        if (existingId != "") {
            url = `${url}/${existingId}`
        }

        console.log("ID:"+existingId);
        console.log("URL:"+url);
        console.log(data);


        let fetchResponse = await authFetch(method, url, { ...data, buildingId: buildingId })

        if (fetchResponse.status != 409) {
            formData.serialNumber = "";
            setModalSuccessOpen(true)
        } else {
            setModalOpen(true)
        }
    } 

    const title = existingId == ""? "Create Asset": "Update Asset";

    const handleProductSelect = (productCode: string) => {
        formData.productCode = productCode;

        let selectedProduct = props.productList.find(it => it.productCode == productCode); 
        if(selectedProduct != null) {
            formData.assetType =  selectedProduct.assetType;
            formData.se04Required =  selectedProduct.se04Required; 
            formData.model =  selectedProduct.model;
            formData.manufacturer =  selectedProduct.manufacturer;
            formData.productCode = selectedProduct.productCode;
            formData.productFamily = selectedProduct.productFamily;
            formData.defaultTemplate = selectedProduct.defaultTemplate;
        }
    }

    const handleProductFamilySelect = (pfamily: string) => {
        formData.productFamily = pfamily;
        let prd = productList.find(it => it.productFamily == pfamily); 

        if(prd != null) {
            formData.productCode = prd.productCode;
        }
    }

    return (
        <Modal show={isOpen} size="lg">
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div style={{ marginBottom: '12px' }}>Please enter the information about the asset</div>

                <Autocomplete
                    options={productFamilies}
                    getOptionLabel={(option) => option.name}
                    value={productFamilies.find(it => it.id == formData.productFamily)}
                    onChange={(event: any, newValue) => {
                        if (newValue == null) {
                            return;
                        }
                        handleProductFamilySelect(newValue.id);

                    }}
                    renderInput={(params) => <TextField {...params} label="Product Family" type="text" margin="dense" fullWidth />}
                />

                <Autocomplete
                    options={productList}
                    getOptionLabel={(option) => option.productCode}
                    groupBy={(option) => {
                        let prf = productFamilies.find(it => it.id == option.productFamily)
                        return prf.name;
                    }}
                    value={productList.find(it => it.productCode == formData.productCode)}
                    onChange={(event: any, newValue) => {
                        if (newValue == null) {
                            return;
                        }
                        handleProductSelect(newValue.productCode);
                    }}
                    renderInput={(params) => <TextField {...params} label="Product Code" type="text" margin="dense" fullWidth />}
                />

                <TextField label="Deployment Phase" type="text" margin="dense" fullWidth select
                    value={formData.assetLocation} onChange={e => formData.assetLocation = e.target.value}>
                    {assetLocations.map(location => (
                        <MenuItem key={location} value={location}>
                            {location}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField label="Status" type="text" margin="dense" fullWidth select
                    value={formData.status} onChange={e => formData.status = e.target.value}>
                    {statuses.map(status => (
                        <MenuItem key={status} value={status}>
                            {status}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField label="Serial Number" type="text" margin="dense" fullWidth error={formError.serialNumber != ""}
                    helperText={formError.serialNumber} value={formData.serialNumber} onChange={e => formData.serialNumber = e.target.value} />

                <TextField label="SE04 Required" type="text" margin="dense" fullWidth select
                    value={formData.se04Required} onChange={e => formData.se04Required = e.target.value}>
                    {se04RequiredItems.map(required => (
                        <MenuItem key={required} value={required}>
                            {required}
                        </MenuItem>
                    ))}
                </TextField>

                <Autocomplete
                    options={defaultTemplates}
                    getOptionKey={(option) => option.id}
                    getOptionLabel={(option) => option.name}
                    value={defaultTemplates.find(it => it.name == formData.defaultTemplate)}
                    onChange={(event: any, newValue) => {
                        if (newValue == null) {
                            return;
                        }
                        formData.defaultTemplate = newValue.name;
                    }}
                    renderInput={(params) => <TextField {...params} label="Default Template" type="text" margin="dense" fullWidth />}
                />

                <TextField label="Asset Type" type="text" margin="dense" fullWidth select disabled
                    value={formData.assetType} onChange={e => formData.assetType = e.target.value}>
                    {assetTypes.map(type => (
                        <MenuItem key={type} value={type}>
                            {type}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField label="Model" type="text" margin="dense" fullWidth error={formError.model != ""} disabled
                    helperText={formError.model} value={formData.model} onChange={e => formData.model = e.target.value} />

                <TextField label="Manufacturer" type="text" margin="dense" fullWidth error={formError.manufacturer != ""} disabled
                    helperText={formError.manufacturer} value={formData.manufacturer} onChange={e => formData.manufacturer = e.target.value} />

            </Modal.Body>
            <Modal.Footer>
                <button onClick={handleClose} className="btn btn-light">
                    Cancel
                </button>
                <button onClick={handleSubmit} className="btn btn-light">
                    Submit
                </button>
                <button onClick={handleSubmitPlus} className="btn btn-light">
                    Submit +
                </button>
            </Modal.Footer>

            <Modal className="modalStyle" show={modalSuccessOpen} centered>
                <Modal.Header>
                    <Modal.Title>Asset Submit</Modal.Title>
                </Modal.Header>
                <Modal.Body>The Asset has been added, Click to close.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalSuccessClose}>Close</Button>
                </Modal.Footer>
            </Modal>

            <Modal className="modalStyle" show={modalOpen} centered>
                <Modal.Header>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>With the given manufacturer, product code and serial number already exists.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalErrorClose}>Close</Button>
                </Modal.Footer>
            </Modal>

        </Modal>
    );
});
