import React, { useEffect } from 'react'
import { useState } from "react";
import { useAuthFetch } from '../useAuthFetch'
import { LoadingTemplate } from '../LoadingTemplate';
import { getApiUrl } from '../authConfig';
//import { AuditLogPopup } from './AuditLogPopup';
import { useParams } from 'react-router-dom';
import { CreateAssetDialog } from '../CreateAssetDialog';
import { InspectionPreviewDialog } from '../InspectionPreviewDialog';

import FileUpload from '../FileDrop'

const faIconMap = {
    pdf: "fa-solid fa-file-pdf",
    doc: "fa-solid fa-file-word",
    docx: "fa-solid fa-file-word",
    xls: "fa-solid fa-file-excel",
    xlsx: "fa-solid fa-file-excel",
    csv: "fa-solid fa-file-csv",
    dwg: "fa-solid fa-compass-drafting",
    xml: "fa-solid fa-code",
    json: "fa-solid fa-code",
    jpg: "fa-solid fa-file-image",
    jpeg: "fa-solid fa-file-image",
    png: "fa-solid fa-file-image",
    bmp: "fa-solid fa-file-image",
    gif: "fa-solid fa-file-image",
    heif: "fa-solid fa-file-image"
}

const faFile = 'fa-solid fa-file';


///Asset details in this page is going ton be in 
export function AssetDetailsPage() {
    const [loading, setLoading] = useState(true);
    const [assetData, setAssetData] = useState(null);
    const [productData, setProductData] = useState([]);
    const [auditLogData, setAuditLogData] = useState([]);
    const [inspectionData, setInspectionData] = useState([]);
    const [fileData, setFileData] = useState([]);
    const [noteText, setNoteText] = useState("");
    const [productFamiliesData, setProductFamiliesData] = useState([]);
    const [templateItemsData, setTemplateItemsData] = useState([]);

    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [logPopupOpen, setLogPopupOpen] = useState(false);
    const params = useParams();
    const authFetch = useAuthFetch();

    const [inspectionPreviewDialogOpen, setInspectionPreviewDialogOpen] = useState(false);
    const [inspectionPreviewData, setinspectionPreviewData] = useState([]);

    const assetId = params.id;

    const requestAssetData = async () => {
        setAssetData(null)
        let fetchResponse = await authFetch('GET', getApiUrl(`assets/${assetId}?building=true`))
        if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
            let responseData = [];

            try {
                responseData = await fetchResponse.json();
            } catch (error) {
                console.log('Request Asset Data Error: ' + error);
            } finally {
                setAssetData(responseData);
            }
        }
    }

    const requestProductFamiliesData = async () => {
        setProductFamiliesData([])
        let fetchResponse = await authFetch('GET', getApiUrl(`products/families`))

        if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
            let responseData = [];

            try {
                responseData = await fetchResponse.json();
            } catch (error) {
                console.log('Request Product Familty Data error: ' + error);
            } finally {
                setProductFamiliesData(responseData);
            }
        }
    }

    const requestTemplateItemsData = async () => {
        setTemplateItemsData([])
        let fetchResponse = await authFetch('GET', getApiUrl(`templates?latest=true`))

        if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
            let responseData = [];

            try {
                responseData = await fetchResponse.json();
            } catch (error) {
                console.log('Request Template Items Data Error: ' + error);
            } finally {
                const sorted = responseData.sort((a, b) => { return a.name > b.name ? 1 : -1 })
                    .map(({ id, name }) => { return { id, name } })
                setTemplateItemsData(sorted);
            }
        }
    }

    const requestProductData = async () => {
        setProductData([])
        let fetchResponse = await authFetch('GET', getApiUrl(`products`))

        if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
            let responseData = [];

            try {
                responseData = await fetchResponse.json();
            } catch (error) {
                console.log('Request Product Data Error: ' + error);
            } finally {
                setProductData(responseData);
            }
        }
    }

    const requestNotesData = async () => {
        setNoteText("")
        let fetchResponse = await authFetch('GET', getApiUrl(`notes/assets/${assetId}`))
        if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
            let responseData = {} as any;

            try {
                responseData = await fetchResponse.json();
            } catch (error) {
                console.log('Request Notes Data Error: ' + error);
            } finally {
                setNoteText(responseData.noteText || "");
            }
        }
    }

    const requestFileData = async () => {
        setFileData([])
        let fetchResponse = await authFetch('GET', getApiUrl(`files/assets/${assetId}`))
        if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
            let responseData = [];

            try {
                responseData = await fetchResponse.json();
            } catch (error) {
                console.log('Request File Data Error: ' + error);
            } finally {
                setFileData(responseData.reverse());
            }
        }
    }

    const requestInspectionData = async () => {
        setFileData([])
        let fetchResponse = await authFetch('GET', getApiUrl(`inspections/assets/${assetId}`))
        if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
            let responseData = [];

            try {
                responseData = await fetchResponse.json();
            } catch (error) {
                console.log('Request Inspection Data Error: ' + error);
            } finally {
                setInspectionData(responseData.reverse());
            }
        }
    }

    const requestAuditLogData = async () => {
        setAuditLogData([])
        let fetchResponse = await authFetch('GET', getApiUrl(`auditLog/assets/${assetId}`))
        if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
            let responseData = [];

            try {
                responseData = await fetchResponse.json();
            } catch (error) {
                console.log('Request Audit Log Data Error: ' + error);
            } finally {
                setAuditLogData(responseData.reverse());
            }
        }
    }

    const downloadFile = async (fileItem) => {
        let fetchResponse = await authFetch('GET', getApiUrl(`files/${fileItem.id}/download`)).then(response => response.blob())
            .then(blob => {
                const downloadUrl = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = downloadUrl;
                a.download = fileItem.fileName; // Optional: Set a default file name
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(downloadUrl); // Cleanup
            })
            .catch(error => {
                console.error('Error downloading the file:', error);
            });
    };

    const openInspection = async (inspectionItem) => {

        setinspectionPreviewData(null)
        let fetchResponse = await authFetch('GET', getApiUrl(`inspections/${inspectionItem.id}?template=true`))
        if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
            let responseData = [];

            try {
                responseData = await fetchResponse.json();
            } catch (error) {
                console.log('Open Inspection Error: ' + error);
            } finally {
                setinspectionPreviewData(responseData);
            }
        }

        setInspectionPreviewDialogOpen(true);
    };

    const requestData = () => {
        let tasks = [
            requestAssetData(),
            requestProductFamiliesData(),
            requestTemplateItemsData(),
            requestProductData(),
            requestNotesData(),
            requestAuditLogData(),
            requestFileData(),
            requestInspectionData()
        ];
        Promise.all(tasks).then(() => setLoading(false)).then(() => { });
    }

    const submitNotesData = async () => {
        let fetchResponse = await authFetch('POST', getApiUrl(`notes/assets/${assetId}`), {
            noteText: noteText
        })
        await requestAuditLogData()
    }

    const handleEditClick = async () => {
        setEditDialogOpen(true);
    }

    const handleEditDialogSubmit = (status: string) => {
        if (status != "cancel") {
            requestData();
        }
        setEditDialogOpen(false);
    }

    const handleInspectionPreviewDialogSubmit = (status: string) => {
        if (status != "cancel") {
            requestData();
        }
        setInspectionPreviewDialogOpen(false);
    }

    const handleLogPopupOpen = () => {
        setLogPopupOpen(true);
    }

    const handleLogPopupClose = () => {
        setLogPopupOpen(false);
    }

    function getAssetStatusBadge(status = "") {
        switch (status.toLowerCase()) {
            case "not commissioned":
                return <span className="badge bg-danger float-end">Not Commissioned</span>
                break;
            case "in progress":
                return <span className="badge bg-info float-end">In Progress</span>
                break;
            case "decommissioned":
                return <span className="badge bg-secondary float-end">Decommissioned</span>
                break;
            case "commissioned":
                return <span className="badge bg-success float-end">Commissioned</span>
                break;
            default:
                return <span className="badge bg-info float-end">{status}</span>
        }
    }

    useEffect(requestData, []);



    return (
        <LoadingTemplate isLoading={loading} >

            <div>
                <h1>{assetData?.manufacturer} {assetData?.model}: SN {assetData?.serialNumber}</h1>
                <div className="row">
                    <div className="col col-12 col-lg-6 col-xl-4">
                        <div className="card">
                            {/*TODO handle edit click*/}
                            <div className="card-title card-title-collapsable">
                                Asset Information
                            </div>
                            <div className="card-body">
                                {getAssetStatusBadge(assetData?.status)}
                                <b>Asset: </b>{assetData?.assetType} <br />
                                <b>Manufacturer: </b>{assetData?.manufacturer} <br />
                                <b>Model: </b>{assetData?.model} <br />
                                <b>Product: </b> {assetData?.productCode} <br /><br />
                                <b>SN: </b>{assetData?.serialNumber} <br />
                                {
                                    assetData?.commSerialNumber != "" ?
                                    <><b>Communications SN: </b>{assetData?.commSerialNumber}<br /><br /></> :
                                    <br />
                                }

                                <b>Location: </b>{assetData?.assetLocation}<br />
                                <br />
                                <span className="text-small">
                                    <b>Created: </b>{new Date(assetData?.creationDate).toDateString()}  by {assetData?.creatorEmail}
                                </span>
                                <button type="button" className="btn btn-sm btn-secondary float-end" onClick={handleEditClick}>edit</button>
                            </div>
                        </div>
                    </div>
                    <div className="col col-12 col-lg-6 col-xl-4">
                        <div className="card">
                            <div className="card-title card-title-collapsable">
                                Inspections
                            </div>
                            <div className="card-body scroll-y" style={{ maxHeight: "350px" }} >
                                {inspectionData.length > 0 ? (
                                    inspectionData.map((inspectionItem, idx) => (
                                        <div key={ idx } className="row mb-3 p-2 clickable hoverable rounded" onClick={() => openInspection(inspectionItem)} >
                                            <div className="col-1">
                                                <span className="fa-stack">
                                                    <i className="fa-solid fa-circle fa-stack-2x"></i>
                                                    <i className="fas fa-list-check fa-stack-1x fa-inverse"></i>
                                                </span>
                                            </div>
                                            <div className="col ms-2">
                                                <b>{inspectionItem.templateId}</b> (v{inspectionItem.templateVersion})<br />
                                                <span className="text-small">{new Date(inspectionItem.creationDate).toLocaleString()} by {inspectionItem.creatorName}</span>
                                            </div>
                                            {/*<div className="col-2">*/}
                                            {/*    <i className="text-large fa-solid fa-eye me-2"></i>*/}
                                            {/*    <i className="text-large fa-solid  fa-file-pdf"></i>*/}
                                            {/*</div>*/}
                                            {/*<div className="col-1">*/}
                                            {/*</div>*/}
                                        </div>
                                    ))
                                ) : (
                                    <span>No inspections performed</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col col-12 col-lg-6 col-xl-4">
                        <div className="card">
                            <div className="card-title card-title-collapsable">
                                Files
                            </div>
                            <div className="card-body scroll-y" style={{ maxHeight: "350px" }}>
                                <FileUpload itemId={assetData?.id} itemType="assets" onComplete={requestFileData} />
                                {fileData.length > 0 ? (
                                    fileData.map((fileItem, idx) => {
                                        //get the appropriate icon if no file preview is available
                                        const fileExtension = fileItem.fileName.split('.').pop().toLowerCase();
                                        const icon = faIconMap[fileExtension] || faFile;

                                        return (
                                            <div className="row mt-1 mb-2 p-2 clickable hoverable rounded" onClick={() => downloadFile(fileItem)} key={fileItem.id}>
                                                <div className="col-1 clickable" >
                                                    {fileItem.filePreview ?
                                                        <img src={fileItem.filePreview ? `data:${fileItem.mimeType};base64,${fileItem.filePreview}` : undefined}
                                                            style={{ maxHeight: "40px", maxWidth: "40px" }}
                                                        />
                                                        :
                                                        <span className="fa-stack">
                                                            <i className="fa-solid fa-circle fa-stack-2x"></i>
                                                            <i className={`fas ${icon} fa-stack-1x fa-inverse`}></i>
                                                        </span>
                                                    }
                                                </div>
                                                <div className="col ms-2">
                                                    {fileItem.fileName}<br />
                                                    <span className="text-small">{fileItem.creatorName
                                                        ? `${new Date(fileItem.creationDate).toLocaleString()} by ${fileItem.creatorName}`
                                                        : new Date(fileItem.creationDate).toLocaleString()}
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    })
                                ) : (
                                    <div className="text-center m-3">No files uploaded</div>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="card">
                            <div className="card-title card-title-collapsable">
                                Notes
                            </div>
                            <div className="card-body">
                                <div className="col col-12">
                                    <textarea style={{ width: "100%" }} placeholder="Notes" value={noteText} onChange={ev => setNoteText(ev.target.value)} />
                                    <button type="button" className="btn btn-sm btn-primary float-end mt-2" onClick={submitNotesData}>save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div className="card">
                            <div className="card-title card-title-collapsable">
                                Audit Log
                            </div>
                            <div className="card-body">
                                <div className="col col-12">
                                    <table className="table" style={{ minWidth: '650' }} aria-label="simple table">
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Notes</th>
                                                    <th align="right">User</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {auditLogData.slice(0, 10).map((log, idx) => (
                                                    <tr key={idx} style={{ border: 0 }}>
                                                        <td scope="row">
                                                            {new Date(log.creationDate).toLocaleString()}
                                                        </td>
                                                        <td>{log.changeText}</td>
                                                        <td align="right">{log.creatorEmail}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <CreateAssetDialog isOpen={editDialogOpen} onSubmit={handleEditDialogSubmit} initialData={{ ...assetData }}
                    productList={productData} buildingId={assetData?.buildingId}
                    productFamilies={productFamiliesData} defaultTemplates={templateItemsData} />

                <InspectionPreviewDialog isOpen={inspectionPreviewDialogOpen} onSubmit={handleInspectionPreviewDialogSubmit} initialData={{ ...inspectionPreviewData }}
                    assetData={assetData}
                />


                {/*<AuditLogPopup isOpen={logPopupOpen} onClose={handleLogPopupClose} initialData={[...auditLogData]} />*/}
            </div>
        </LoadingTemplate>
    );
};


