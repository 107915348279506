import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useState } from "react";
import { useAuthFetch } from '../useAuthFetch'
import { LoadingTemplate } from '../LoadingTemplate';
import { useNavigate, useParams } from 'react-router-dom';
import { CreateAssetDialog } from '../CreateAssetDialog';

import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { SideBarDef, IRichCellEditorParams, ColDef } from "ag-grid-community";

import { requestAssetData } from "../hooks/Asset";
import { requestProductFamilyData } from "../hooks/ProductFamily";
import { requestProductData } from "../hooks/Product";
import { requestBuildingData } from "../hooks/Building";
import { requestTemplateData } from "../hooks/Template";

import Dropdown from "react-bootstrap/Dropdown"
import DropdownButton from "react-bootstrap/DropdownButton"
import Button from 'react-bootstrap/Button';
import "../assets/css/formItems.css"

class QABadgeCellRenderer {
    init(params) {
        this.eGui = document.createElement('span');
        this.eGui.className = 'badge'; // Bootstrap badge class

        if (!params.data || !params.data[params.column.colId]) {
            // these fields don't exist when grouping
            return;
        }

        if (typeof params.data.qaReview === 'string' || params.data.qaReview instanceof String) {
            //Click Event Logic
            console.log('Value:' + params.data.qaReview);
            if (params.data.qaReview == "Passed") {
                this.eGui.classList.add('bg-success', 'qa-badge');
                let currentDate = new Date();
                this.eGui.innerText = currentDate.toISOString().split('T')[0]; // Set the badge text
            } else if (params.data.qaReview == "Failed") {
                this.eGui.classList.add('bg-danger', 'qa-badge');
                let currentDate = new Date();
                this.eGui.innerText = currentDate.toISOString().split('T')[0]; // Set the badge text
            } else if (params.data.qaReview == "Incomplete") {
                this.eGui.classList.add('bg-secondary', 'qa-badge');
                this.eGui.innerText = "Incomplete"; // Set the badge text
            } else {
                console.log('Cancelled');
                return;
            }
        } else {
            // Set styles based on value or other conditions
            if (params.data[params.column.colId] == null || params.data[params.column.colId].passed == null) {
                this.eGui.classList.add('bg-secondary', 'qa-badge');
                this.eGui.innerText = 'Incomplete';
            } else if (params.data[params.column.colId].passed) {
                this.eGui.classList.add('bg-success', 'qa-badge');
                this.eGui.innerText = params.data[params.column.colId].date; // Set the badge text
            }
            else if (!params.data[params.column.colId].passed) {
                this.eGui.classList.add('bg-danger', 'qa-badge');
                this.eGui.innerText = params.data[params.column.colId].date; // Set the badge text
            }
        }
    }

    getGui() {
        return this.eGui;
    }
}

export function TestPage() {
    const gridRef = useRef<AgGridReact>(null);
    const [loading, setLoading] = useState(true);
    const [buildingData, setBuildingData] = useState([]);
    const [assetData, setAssetData] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [productData, setProductData] = useState([]);
    const [productFamiliesData, setProductFamiliesData] = useState(null);

    const qaItems = ["Cancel", "Incomplete", "Passed", "Failed"];

    const navigate = useNavigate();
    const authFetch = useAuthFetch();
    const params = useParams();
    const [buildingSelect, setBuildingSelect] = useState([]);

    let buildingId = params.buildingId || "";

    if (buildingId == "" && buildingData.length != 0) {
        buildingId = buildingData[0].id;
    }

    const requestData = async () => {
        setLoading(true);
        await requestAssetData(authFetch, { buildingId }).then(res => { return res; }).then((data) => {
            setAssetData(data);
        });
        setLoading(false);
    }

    const requestInitialData = async () => {
        await requestProductFamilyData(authFetch).then(res => { return res; }).then((data) => { setProductFamiliesData(data); });
        await requestProductData(authFetch).then(res => { return res; }).then((data) => { setProductData(data); });
        await requestBuildingData(authFetch).then(res => { return res; }).then((data) => {
            setBuildingData(data);
            if (buildingId == "" && data.length != 0) {
                buildingId = data[0].id;
                setBuildingSelect(data[0].name);
            } else {
                setBuildingSelect(data.find(it => it.id == buildingId).name);
            }
        });
        await requestAssetData(authFetch, { buildingId }).then(res => { return res; }).then((data) => {

            // manually add some fields to asset data as a test:
            data[0].qaReview = {
                passed: true,
                date: '2024-09-01',
                user: 'marcela@qmeters.com'
            };
            data[1].qaReview = {
                passed: false,
                date: '2024-09-01',
                user: 'marcela@qmeters.com'
            };
            data[2].qaReview = {
                passed: null,
            };
            data[3].qaReview = null;

            data[0].qaApproval = {
                passed: true,
                date: '2024-09-02',
                user: 'terry@qmeters.com'
            };

            setAssetData(data);
           // console.log(buildingId);
           // setBuildingSelect(buildingData.find(it => it.id == buildingId));
        });

        setLoading(false);
    }

    useEffect(() => { requestInitialData() }, [params.buildingId]);

    const handleDialogOpen = () => { setDialogOpen(true); }

    const handleDialogSubmit = (status: string) => {
        if (status != "cancel") {
            requestData();
        }
        setDialogOpen(false);
    }

    const [colDefs, setColDefs] = useState<ColDef[]>([
        { field: "assetType", enableRowGroup: true, maxWidth: 150 },
        { field: "productCode", enableRowGroup: true, maxWidth: 175 },
        { field: "manufacturer", enableRowGroup: true, maxWidth: 125 },
        { field: "model", enableRowGroup: true, maxWidth: 150 },
        { headerName: "Serial Num", field: "serialNumber", maxWidth: 125 },
        {
            headerName: "QA Review",
            field: "qaReview",
            maxWidth: 140,
            cellEditor: "agRichSelectCellEditor",
            cellEditorParams: {
                values: qaItems,
            } as IRichCellEditorParams,
            valueGetter: (params) =>  { return  params.data && params.data.qaReview ? qaValueGetter(params.data.qaReview) : 'Cancel' } ,
            //cellStyle: (params) => { return qaFieldStyle(params.data) }
            cellRenderer: QABadgeCellRenderer
        },
        {
            headerName: "QA Approval",
            field: "qaApproval",
            maxWidth: 140,
            valueGetter: (params) => { return params.data && params.data.qaApproval ? qaValueGetter(params.data.qaApproval) : 'Incomplete' },
            //cellStyle: (params) => { return qaFieldStyle(params.data) }
            cellRenderer: QABadgeCellRenderer
        },
        { headerName: "Communications Serial Num", field: "commSerialNumber" },
        { headerName: "S-E-04 Required", field: "se04Required", maxWidth: 125 },
        { headerName: "Status", field: "status", maxWidth: 175 }
       
    ]);

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            editable: true,
        }
    }, []);

    function qaValueGetter(data) {
        if (data == null || data.passed == null) {
            return "Incomplete"
        }

        if (data.passed) {
            return "Passed"
        }

        return "Failed";
    }

    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current!.api.setGridOption(
            "quickFilterText",
            (document.getElementById("filter-text-box") as HTMLInputElement).value,
        );
    }, []);

    const statusBar = useMemo(() => {
        return {
            statusPanels: [
                { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
                { statusPanel: 'agFilteredRowCountComponent', align: 'left' }
            ]
        };
    }, []);

    const sideBar = useMemo<
        SideBarDef | string | string[] | boolean | null
    >(() => {
        return {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                        suppressColumnFilter: true,
                        suppressColumnSelectAll: true,
                        suppressColumnExpandAll: true,
                    },
                },
            ],
            defaultToolPanel: "",
        };
    }, []);

    const onCsvExport = useCallback(() => {
        gridRef.current!.api.exportDataAsCsv();
    }, []);

    const onExcelExport = useCallback(() => {
        gridRef.current!.api.exportDataAsExcel();
    }, []);

    return (
        <LoadingTemplate isLoading={loading}>
            <div>
                <Button variant="primary" className="rounded-circle modalAddButton" onClick={handleDialogOpen}>+</Button>

                <div className="titleRow">
                    <div style={{ flexGrow: '1' }}>This is a Dev Testing Page</div>
                    <div className="dropdown-container">
                        <div className="dropdown-label">Building</div>
                        <DropdownButton variant="outline-secondary" className="page-dropdown" id="dropdown-basic" title={buildingSelect}>
                            {buildingData.map((item) => (
                                <Dropdown.Item key={item.id} onClick={() => { navigate(`/test/${item.id}`); setBuildingSelect(item.name); }}>
                                    {item.name}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col col-8 col-lg-3 col-sm-6">
                        <input type="text" id="filter-text-box" className="form-control" placeholder="Filter..." onInput={onFilterTextBoxChanged} />
                    </div>
                    <div className="col">
                        <i className="fa-solid fa-file-excel fa-2x me-2 clickable hoverable" onClick={onExcelExport} style={{ lineHeight: '1.25em' }}></i>
                        <i className="fa-solid fa-file-csv fa-2x clickable hoverable" onClick={onCsvExport}></i>
                    </div>
                    {/*<Button style={{ float: 'right', marginRight: '5px' }} size="small" variant="contained" endIcon={<DownloadForOfflineIcon />} onClick={onBtnExport}>Download Grid</Button>*/}
                </div>

                <div className="ag-theme-quartz" style={{ height: 600 }}>
                    <AgGridReact ref={gridRef} rowData={assetData} statusBar={statusBar} columnDefs={colDefs} defaultColDef={defaultColDef} sideBar={sideBar} rowStyle={{ cursor: "pointer" }} rowGroupPanelShow={"always"}
                        onRowClicked={(e) => {
                            //navigate(`/asset/${e.data.id}`)
                        }
                    } />
                </div>

                <CreateAssetDialog isOpen={dialogOpen} onSubmit={handleDialogSubmit}
                    buildingId={buildingId} productList={productData}
                    productFamilies={productFamiliesData}
                />
            </div>
        </LoadingTemplate>
    );
};