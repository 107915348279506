import React, { useCallback, useEffect, useRef, useMemo, Component } from 'react'
import { useState } from "react";
import { useAuthFetch } from '../useAuthFetch'
import { LoadingTemplate } from '../LoadingTemplate';
import { getApiUrl } from '../authConfig';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CreateAssetDialog } from '../CreateAssetDialog';
import { InspectionPreviewDialog } from '../InspectionPreviewDialog';
import { InspectionPreview } from '../InspectionPreview';
import moment from 'moment';

import Dropdown from "react-bootstrap/Dropdown"
import DropdownButton from "react-bootstrap/DropdownButton"
import Button from 'react-bootstrap/Button';
import "../assets/css/formItems.css";

import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { SideBarDef, GridState, ICellRendererParams, ValueFormatterParams, CellClickedEvent } from "ag-grid-community";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";

import {
    ColDef,
    IRichCellEditorParams,
} from "ag-grid-community";

import { requestBuildingData } from "../hooks/Building";
import { requestProductFamilyData } from "../hooks/ProductFamily";
import { requestProductData } from "../hooks/Product";
import { requestInspectionData } from "../hooks/Inspection";
import { requestTemplateData } from "../hooks/Template";

import html2canvas from 'html2canvas-pro';
import jsPDF from 'jspdf';
import jsZip from 'jszip';
import ReactDomServer from 'react-dom/server';
import { saveAs } from 'file-saver';

import Modal from 'react-bootstrap/Modal';
import { useMsal } from '@azure/msal-react';

export function AssetsPage() {
    const [ loading, setLoading ] = useState(true); 
    const [ buildingData, setBuildingData ] = useState([]);
    const [ assetData, setAssetData ] = useState([]);
    const [ dialogOpen, setDialogOpen ] = useState(false);
    const [ productData, setProductData ] = useState([]);
    const [productFamiliesData, setProductFamiliesData] = useState([]);
    const [templateItemsData, setTemplateItemsData] = useState([]);
    const [rowChecked, setRowChecked] = useState([]);
    const [headerChecked, setHeaderChecked] = useState(false);
    const gridRef = useRef<AgGridReact>(null);
    const qaItems = ["Cancel", "Incomplete", "Passed", "Failed"];
    const [modalQAChangeOpen, setModalQAChangeOpen] = useState(false); 
    const [assetGridModalItem, setAssetGridModalItem] = useState("");

    const [inspectionPreviewDialogOpen, setInspectionPreviewDialogOpen] = useState(false);
    const [inspectionPreviewOpen, setInspectionPreviewOpen] = useState(false);

    const [inspectionPreviewData, setinspectionPreviewData] = useState([]);
    const [inspectionAssetItemData, setInspectionAssetItemData] = useState([]);

    const [inspectionData, setInspectionData] = useState(null);

    const navigate = useNavigate();
    const authFetch = useAuthFetch(); 
    const params = useParams(); 
    const { accounts } = useMsal();

    const [buildingSelect, setBuildingSelect] = useState([]);

    let buildingId = params.buildingId || "";

    if(buildingId == "" && buildingData.length != 0) {
        buildingId = buildingData[0].id;
    }

    const requestAssetData = async () => {
        setAssetData([])
        let fetchResponse = await authFetch('GET', getApiUrl(`assets/building/${buildingId}?building=true&latestInspection=true`))

        if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
            let responseData = [];

            try {
                responseData = await fetchResponse.json();
            } catch (error) {
                console.log('Request Asset Data Error: ' + error);
            } finally {
                setAssetData(responseData);
            }
        }
    }

    const requestData = async ()=> {
        setLoading(true);
        await requestAssetData();
        setLoading(false);
    }

    const requestInitialData = async () => {
        await requestProductFamilyData(authFetch).then(res => { return res; }).then((data) => { setProductFamiliesData(data); });
        await requestProductData(authFetch).then(res => { return res; }).then((data) => { setProductData(data); });
        await requestBuildingData(authFetch, "slim").then(res => { return res; }).then((data) => {
            setBuildingData(data);
            if (buildingId == "" && data.length != 0) {
                buildingId = data[0].id;
                setBuildingSelect(data[0].name);
            } else {
                setBuildingSelect(data.find(it => it.id == buildingId).name);
            }
        });
        await requestAssetData();
        await requestTemplateData(authFetch).then(res => { return res; }).then((data) => { setTemplateItemsData(data); });
        setLoading(false);
    }

    useEffect(() => { requestInitialData()}, [params.buildingId]);

    const handleDialogOpen = () => {
       setDialogOpen(true); 
    }

    const handleDialogSubmit = (status: string) => {
        if(status != "cancel") {
            requestData(); 
        }
        setDialogOpen(false);
    }

    const downloadPdf = async (assetItem) => {

        const jsPdf = new jsPDF('p', 'pt', 'letter');

        if (assetItem.length === 1) {
            assetItem.map((item) => {
                requestInspectionData(authFetch, item.id).then(res => { return res; }).then((rInspectionData) => {
                    const htmlString = ReactDomServer.renderToStaticMarkup(<InspectionPreview assetData={item} inspectionData={rInspectionData} />);
                    const iframe = document.createElement("iframe");
                    document.body.appendChild(iframe);

                    iframe.contentWindow.document.write(htmlString);

                    html2canvas(iframe.contentWindow.document.body, { useCORS: true, allowTaint: true, scrollY: 0 }).then(canvas => {
                        const image = { type: 'jpeg', quality: 0.98 };
                        const margin = [0.5, 0.5];
                        const filename = 'myfile.pdf';

                        var imgWidth = 8.5;
                        var pageHeight = 11;

                        var innerPageWidth = imgWidth - margin[0] * 2;
                        var innerPageHeight = pageHeight - margin[1] * 2;

                        // Calculate the number of pages.
                        var pxFullHeight = canvas.height;
                        var pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth));
                        var nPages = Math.ceil(pxFullHeight / pxPageHeight);

                        // Define pageHeight separately so it can be trimmed on the final page.
                        var pageHeight = innerPageHeight;

                        // Create a one-page canvas to split up the full image.
                        var pageCanvas = document.createElement('canvas');
                        var pageCtx = pageCanvas.getContext('2d');
                        pageCanvas.width = canvas.width;
                        pageCanvas.height = pxPageHeight;

                        // Initialize the PDF.
                        var pdf = new jsPDF('p', 'in', [8.5, 11]);

                        for (var page = 0; page < nPages; page++) {
                            // Trim the final page to reduce file size.
                            if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
                                pageCanvas.height = pxFullHeight % pxPageHeight;
                                pageHeight = (pageCanvas.height * innerPageWidth) / pageCanvas.width;
                            }

                            // Display the page.
                            var w = pageCanvas.width;
                            var h = pageCanvas.height;
                            pageCtx.fillStyle = 'white';
                            pageCtx.fillRect(0, 0, w, h);
                            pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);

                            // Add the page to the PDF.
                            if (page > 0) pdf.addPage();

                            var imgData = pageCanvas.toDataURL('image/' + image.type, image.quality);
                            pdf.addImage(imgData, image.type, margin[1], margin[0], innerPageWidth, pageHeight);
                        }
                        pdf.save("inspection-" + item.id + ".pdf");
                        iframe.remove();
                        canvas.remove();
                    });
                });
            })
 
        } else {
            var zip = new jsZip();

            assetItem.map((item) => {
                requestInspectionData(authFetch, item.id).then(res => { return res; }).then((rInspectionData) => {
                const htmlString = ReactDomServer.renderToStaticMarkup(<InspectionPreview assetData={item} inspectionData={rInspectionData} />);
                const iframe = document.createElement("iframe");
                document.body.appendChild(iframe);
                iframe.contentWindow.document.write("<html><head></head><body><div>" + htmlString + "</div></body></html>");

                html2canvas(iframe.contentWindow.document.body).then(canvas => {
                    const image = { type: 'jpeg', quality: 0.98 };
                    const margin = [0.5, 0.5];
                    const filename = 'myfile.pdf';

                    var imgWidth = 8.5;
                    var pageHeight = 11;

                    var innerPageWidth = imgWidth - margin[0] * 2;
                    var innerPageHeight = pageHeight - margin[1] * 2;

                    // Calculate the number of pages.
                    var pxFullHeight = canvas.height;
                    var pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth));
                    var nPages = Math.ceil(pxFullHeight / pxPageHeight);

                    // Define pageHeight separately so it can be trimmed on the final page.
                    var pageHeight = innerPageHeight;

                    // Create a one-page canvas to split up the full image.
                    var pageCanvas = document.createElement('canvas');
                    var pageCtx = pageCanvas.getContext('2d');
                    pageCanvas.width = canvas.width;
                    pageCanvas.height = pxPageHeight;

                    // Initialize the PDF.
                    var pdf = new jsPDF('p', 'in', [8.5, 11]);

                    for (var page = 0; page < nPages; page++) {
                        // Trim the final page to reduce file size.
                        if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
                            pageCanvas.height = pxFullHeight % pxPageHeight;
                            pageHeight = (pageCanvas.height * innerPageWidth) / pageCanvas.width;
                        }

                        // Display the page.
                        var w = pageCanvas.width;
                        var h = pageCanvas.height;
                        pageCtx.fillStyle = 'white';
                        pageCtx.fillRect(0, 0, w, h);
                        pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);

                        // Add the page to the PDF.
                        if (page > 0) pdf.addPage();

                        var imgData = pageCanvas.toDataURL('image/' + image.type, image.quality);
                        pdf.addImage(imgData, image.type, margin[1], margin[0], innerPageWidth, pageHeight);
                    }
                    zip.file(`inspection- ${item.id}.pdf`, pdf.output('blob'));
                    iframe.remove();
                    canvas.remove();
                })
            });
            })
            setTimeout(() => {
                const content = zip.generateAsync({ type: 'blob' }).then(function (content) {
                    saveAs(content, `inspectionReport.zip`);
                });
            }, 2000);
        }

    };

    const CustomInspectionLinkComponent = (props) => {
        if (props != null && props.data != null && props.data.lastInspectionId != null) {
            return (
                <Button type="button" className="btn btn-primary btn-sm" onClick={(e) => {
                    requestInspectionData(authFetch, props.data.id).then(res => { return res; }).then((rInspectionData) => {
                        setInspectionData(rInspectionData);
                        e.preventDefault();
                        setInspectionPreviewOpen(true);
                        setInspectionAssetItemData(props.data);
                    });
                }}>{moment(props.data.lastInspectionDate).format('YYYY-MM-DD')}</Button>
                /*<Link to={{ pathname: '/inspectionpreview/', }} state={{ assetData: props.data }}>{moment(props.data.lastInspectionDate).format('YYYY-MM-DD, h:mm:ss A')}</Link>*/ 
            )
        }
    };

    const handleChecked = (e, assetId) =>{
        let prev = rowChecked;
        let itemIndex = prev.indexOf(assetId);
        if (itemIndex !== -1) {
            prev.splice(itemIndex, 1);
        } else {
            prev.push(assetId);
        }
        setRowChecked([...prev]);
    }

    const agGridheaderCheckbox = (props) => {
        return (
            <input type="checkbox" onChange={(e) => {
                setHeaderChecked(e.target.checked);
            }}
            />
        )
    }

    const agGridCheckbox = (props) => {
        return (
            <input type="checkbox" checked={props.data != null ? rowChecked.indexOf(props.data) > -1 : false} onChange={(e) => {
                e.preventDefault();
                handleChecked(e, props.data);
                }}
                />
        )
    }

    const qaChangeConfirm = async (params) => {
        if (params.newValue != "Cancel") {
            setAssetGridModalItem(params);
            setModalQAChangeOpen(true);
        }
    }

    /*const openInspection = async (assetItem) => {
        setInspectionAssetItemData(assetItem);
        setinspectionPreviewData(null);
        let fetchResponse = await authFetch('GET', getApiUrl(`inspections/assets/${assetItem.id}?template=true`))
        if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
            let responseData = [];
            try {
                responseData = await fetchResponse.json();
                responseData = responseData.reverse();
            } catch (error) {
                console.log('Open Inspection Error: ' + error);
            } finally {
                setinspectionPreviewData(responseData[0]);
            }
        }

        setInspectionPreviewDialogOpen(true);
    };*/

    const cellClickEvent = async (params) => {

        let columnSelected = params.colDef.field.split('.')[0];
        
        if (params.newValue === "Cancel") {
            if (columnSelected === "techReview") {
                params.data.techReview.completionDate = params.oldValue;
            } else if (columnSelected === "qaReview") {
                params.data.qaReview.completionDate = params.oldValue;
            } else if (columnSelected === "qaApproval") {
                params.data.qaApproval.completionDate = params.oldValue;
            }

            params.api.refreshCells({ columns: [columnSelected + ".completionDate"] });
        } else {
            const date = new Date();
            let statusType = 0;
            if (params.newValue === "Pass") {
                statusType = 1;
            } else if (params.newValue === "Fail"){
                statusType = 2;
            }

            const jsonArray = '{"qaStatus": "' + statusType +'", "completionDate": "' + date.toISOString() + '", "updatedBy": "' + accounts[0]?.name + '"}'
            let data = params.data;
            if (columnSelected === "techReview") {
                data.techReview = JSON.parse(jsonArray);
                params.data.qaApproval.completionDate = date;
            } else if (columnSelected === "qaReview") {
                data.qaReview = JSON.parse(jsonArray);
                params.data.qaReview.completionDate = date;
            } else if (columnSelected === "qaApproval") {
                data.qaApproval = JSON.parse(jsonArray);
                params.data.qaApproval.completionDate = date;
            }

            params.api.refreshCells({ columns: [columnSelected + ".completionDate"] });

            let url = getApiUrl("assets");
            url = `${url}/${params.data.id}`
     
             let fetchResponse = await authFetch("PUT", url, { ...data, buildingId: buildingId })
             if (fetchResponse.status != 409) {
                 console.log("UPDATED!");
             } else {
                 console.log("ERROR");
             }
        }
    }

    function formatterCell(params: ValueFormatterParams) {

        let columnSelected = params.colDef.field.split('.')[0];
        let returnValue = "";
        if (params.data == null) {
            returnValue = params.value;
        } else {
            if (params.data != null && params.data.qaApproval != null && params.data.qaApproval.qaStatus != '0' && columnSelected === "qaApproval") {
                returnValue = params.value == null ? "" : moment(params.value).format('YYYY-MM-DD');
            } else if (params.data != null && params.data.qaReview != null && params.data.qaReview.qaStatus != '0' && columnSelected === "qaReview") {
                returnValue = params.value == null ? "" : moment(params.value).format('YYYY-MM-DD');
            } else if (params.data != null && params.data.techReview != null && params.data.techReview.qaStatus != '0' && columnSelected === "techReview") {
                returnValue = params.value == null ? "" : moment(params.value).format('YYYY-MM-DD');
            }
        }
        return returnValue;
    }

    const gridAssetLinkComponent = (props) => {
        return (
            <th align="right">
                <Button type="button" className="btn btn-primary btn-sm" onClick={(ev) => {
                    ev.stopPropagation();
                    navigate(`/asset/${props.data.id}`)
                }}><i className="fa fa-external-link" />
                </Button>
            </th>
        )
    };

    const [colDefs, setColDefs] = useState([
        { headerName: "", suppressHeaderMenuButton: true, field: "id", maxWidth: 50, cellRenderer: gridAssetLinkComponent },
        { field: "RowSelct", sortable: false, suppressHeaderMenuButton: true, maxWidth: 45, headerName: " ", cellRenderer: agGridCheckbox, /*headerComponent: agGridheaderCheckbox*/ },
        { field: "assetType", maxWidth: 100 },
        { headerName: "Prod Code", field: "productCode", maxWidth: 150 },
        { field: "manufacturer", maxWidth: 125 },
        { field: "model", maxWidth: 100 },
        { headerName: "Serial Num", field: "serialNumber", maxWidth: 150 },
        { headerName: "Comm Serial Num", field: "commSerialNumber" },
        { headerName: "Se04 Require", field: "se04Required", maxWidth: 105, minWidth: 105 },
        { field: "status", maxWidth: 125 },
        {
            headerName: "Tech Review", field: "techReview.completionDate", stopEditingwhenGridLosesFocus: true,
            valueFormatter: formatterCell,
            cellClassRules: {
                'cellPass': (params) => { return params.data.techReview.qaStatus == '1';},
                'cellFail': (params) => { return params.data.techReview.qaStatus == '2';},
            },
            maxWidth: 150, minWidth: 150, cellEditor: 'agSelectCellEditor',
            onCellClicked: params => { params.event.preventDefault() },
            tooltipField: "techReview.updatedBy",
            cellEditorParams: {
                values: ['Cancel', 'Pass', 'Fail',],
            } as IRichCellEditorParams,
            onCellValueChanged: (e) => { /*qaChangeConfirm(e);*/ cellClickEvent(e); }
        },
        {
            headerName: "QA Review", field: "qaReview.completionDate", stopEditingwhenGridLosesFocus: true,
            valueFormatter: formatterCell,
                    cellClassRules: {
                        'cellPass': (params) => { return params.data.qaReview.qaStatus == '1';},
                        'cellFail': (params) => { return params.data.qaReview.qaStatus == '2';},
                    },
            maxWidth: 150, minWidth: 150, cellEditor: 'agSelectCellEditor',
                    onCellClicked: params => { params.event.preventDefault() },
            tooltipField: "qaReview.updatedBy",
            cellEditorParams: {
                values: ['Cancel','Pass','Fail',],
            } as IRichCellEditorParams,
            onCellValueChanged: (e) => { /*qaChangeConfirm(e);*/ cellClickEvent(e); }
        }, {
            headerName: "QA Approval", field: "qaApproval.completionDate", stopEditingwhenGridLosesFocus: true,
            valueFormatter: formatterCell,
            cellClassRules: {
                'cellPass': (params) => { return params.data.qaApproval.qaStatus == '1';},
                'cellFail': (params) => { return params.data.qaApproval.qaStatus == '2';},
            },
            maxWidth: 150, minWidth: 150, cellEditor: 'agSelectCellEditor',
            onCellClicked: params => { params.event.preventDefault() },
            tooltipField: "qaApproval.updatedBy",
            cellEditorParams: {
                values: ['Cancel', 'Pass', 'Fail',],
            } as IRichCellEditorParams,
            onCellValueChanged: (e) => { /*qaChangeConfirm(e);*/ cellClickEvent(e); }
        },
        { headerName: "Inspection", minWidth: 125, maxWidth: 125, cellRenderer: CustomInspectionLinkComponent }
    ]);

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            flex: 1,
            minWidth: 100,
            editable: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
        };
    }, []);

    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current!.api.setGridOption(
            "quickFilterText",
            (document.getElementById("filter-text-box") as HTMLInputElement).value,
        );
    }, []);

    const statusBar = useMemo(() => {
        return {
            statusPanels: [
                { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
                { statusPanel: 'agFilteredRowCountComponent', align: 'left' },
                { statusPanel: 'agSelectedRowCountComponent', align: 'left' }
            ]
        };
    }, []);

    const handleInspectionPreviewDialogClose = (status: string) => {
        if (status != "cancel") {
            requestData();
        }
        setInspectionPreviewDialogOpen(false);
    }

    const initialState = useMemo<GridState>(() => {
        return {
            rowSelection: ["2"],
        };
    }, []);

    const sideBar = useMemo<
        SideBarDef | string | string[] | boolean | null
    >(() => {
        return {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                        suppressColumnFilter: true,
                        suppressColumnSelectAll: true,
                        suppressColumnExpandAll: true,
                    },
                },
            ],
            defaultToolPanel: "",
        };
    }, []);

    const onBtnExport = useCallback(() => {
        gridRef.current!.api.exportDataAsCsv();
    }, []);

    const onpdfExport = () => {
        //convertHTMLToPdf();
        downloadPdf(rowChecked);
    }

    /*const onpdfExport = useCallback(() => {
        //downloadPdf(rowChecked);
    }, []);*/

    const handleClose = () => {
        setInspectionPreviewOpen(false);
    };

    return (
        <LoadingTemplate isLoading={loading}>
            <div>
                <Button variant="primary" className="rounded-circle modalAddButton" onClick={handleDialogOpen}>+</Button>

                <div className="titleRow">
                    <div style={{flexGrow: '1'}}>Assets</div>
                    <div className="dropdown-container">
                        <div className="dropdown-label">Building</div>
                        <DropdownButton variant="outline-secondary" className="page-dropdown" id="dropdown-basic" title={buildingSelect}>
                            {buildingData.map((item) => (
                                <Dropdown.Item key={item.id} onClick={() => { setRowChecked([]); navigate(`/assets/${item.id}`); setBuildingSelect(item.name); }}>
                                    {item.name}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>
                    </div>
                </div>

                <div style={{ margin: '5px' }}>
                    <input type="text" id="filter-text-box" placeholder="Filter..." onInput={onFilterTextBoxChanged} style={{ height: '34px' }} />
                    {rowChecked && rowChecked.length > 0 && <Button style={{ marginLeft: '5px' }} className="btn btn-primary btn-sm" onClick={()=> onpdfExport()}>Download PDF</Button>}
                    <Button type="button" className="btn btn-primary btn-sm" style={{ float: 'right', marginRight: '5px' }} onClick={onBtnExport}>DOWNLOAD GRID&nbsp;&nbsp;<i className="fa fa-download"/></Button>
                </div>

                <div className="ag-theme-quartz" style={{ height: 600 }}>
                    <AgGridReact ref={gridRef} rowData={assetData} statusBar={statusBar} columnDefs={colDefs} defaultColDef={defaultColDef} sideBar={sideBar}
                        rowStyle={{ cursor: "pointer" }} initialState={initialState}
                        onRowClicked={(row) => {
                            if (row.event.defaultPrevented) {
                                return null;
                            }
                            //navigate(`/asset/${row.data.id}`)
                        }
                    } />
                </div>

                <CreateAssetDialog isOpen={dialogOpen} onSubmit={handleDialogSubmit} 
                    buildingId={buildingId} productList={productData} productFamilies={productFamiliesData} defaultTemplates={templateItemsData} />

                {/*<InspectionPreviewDialog isOpen={inspectionPreviewDialogOpen} onSubmit={handleInspectionPreviewDialogClose}
                    initialData={{ ...inspectionPreviewData }} assetData={...inspectionAssetItemData} />*/}

                <Modal show={inspectionPreviewOpen} size="xl">
                    <Modal.Body>
                        <div className="modalinspection">
                            <Button onClick={handleClose} type="button" className="btn btn-secondary">
                                Close
                            </Button>
                        </div>
                        <InspectionPreview onSubmit={handleInspectionPreviewDialogClose}
                            inspectionData={{ ...inspectionData }} assetData={...inspectionAssetItemData} />
                    </Modal.Body>
                </Modal>

                <Modal className="modalStyle" show={modalQAChangeOpen} centered>
                    <Modal.Header>
                        <Modal.Title>Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to change the QA Value?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" >Yes</Button>
                        <Button variant="secondary" onClick={() => { setModalQAChangeOpen(false); setAssetGridModalItem(null); }}>Cancel</Button>
                    </Modal.Footer>
                </Modal>
            
            </div>
        </LoadingTemplate>
    );
};

