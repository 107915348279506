import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import React from "react"
import { createRoot } from "react-dom/client";
import $ from 'jquery';


import { App } from "./App";
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';


import { configure  as mobxConfigure } from "mobx"

mobxConfigure({
    enforceActions: "never",
})

const container = document.getElementById("app");
const root = createRoot(container)

const msalInstance = new PublicClientApplication(msalConfig);

root.render(
    <React.StrictMode>
        {/*<StyledEngineProvider injectFirst>*/}
            {/*<CssBaseline/>*/}
            <MsalProvider instance={msalInstance}>
                <App/>
            </MsalProvider>
        {/*</StyledEngineProvider>*/}
    </React.StrictMode>
);