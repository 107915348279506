import React from 'react'
import { useState } from "react";
import { LoadingTemplate } from '../LoadingTemplate';

import "../assets/css/roiPage.css"

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';

export function QAPage() {
    const [ loading, setLoading ] = useState(false); 

    return (
        <LoadingTemplate isLoading={loading}>
            <div>

                {/*<div className="titleRow">
                    <div style={{flexGrow: '1'}}>ROI PAGE</div>
                </div>*/}
                <div className="row-container">
                    <div className="roi-header">
                        <img src={require("../assets/images/logo.png")} className="roi-header-logo" />
                        <div className="roi-header-text">Record of Meter Installation - [Manufacturer] [Model] Meter</div>
                    </div>

                    <div className="roi-body">
                        <div className="roi-body-header">
                            <div className="roi-body-header-text">General Info</div>
                        </div>

                        <div className="roi-body-container-half">
                            <div className="roi-body-container-label">Client:</div>
                            <div className="roi-body-container-value value-red">Client_Name</div>
                            <div className="roi-body-container-label">Address:</div>
                            <div className="roi-body-container-value">From Building Info</div>
                            <div className="roi-body-container-label">QMC Reference:</div>
                            <div className="roi-body-container-value value-red">NO PLACEHOLDER ON MOSAIC YET</div>
                            <div className="roi-body-container-label">Contractor No.</div>
                            <div className="roi-body-container-value value-red">Contractor_Num</div>
                        </div>
                        <div className="roi-body-container-half roi-body-container-right">
                            <div className="roi-body-container-data-half">
                                <div className="roi-body-container-label">Serial No.</div>
                                <div className="roi-body-container-value value-blue">Serial Num</div>
                                <div className="roi-body-container-label">Inspection No.</div>
                                <div className="roi-body-container-value value-red">Inspection_Num</div>
                                <div className="roi-body-container-label">Installation Date:</div>
                                <div className="roi-body-container-value value-green">Installation_Date</div>
                                <div className="roi-body-container-label">Meter Location:</div>
                                <div className="roi-body-container-value value-green">Physical_Location</div>
                            </div>

                            <div className="roi-body-container-data-half">
                                <div className="roi-body-container-label">Approval No.</div>
                                <div className="roi-body-container-value value-red">Approval_Num</div>
                                <div className="roi-body-container-label">Installer Name:</div>
                                <div className="roi-body-container-value value-green">Installer_Name</div>
                                <div className="roi-body-container-label">Sealing Year:</div>
                                <div className="roi-body-container-value value-orange">Bench_Sealing_Year</div>
                            </div>
                        </div>

                        <div className="roi-body-header">
                            <div className="roi-body-header-text">Sealing Parameters</div>
                        </div>
                        <div className="roi-body-container-half">
                            <div className="roi-body-container-label">Sealing Parameters:</div>
                            <div className="roi-body-container-value value-purple">SealParameters: [""],</div>
                            <div className="roi-body-container-spacer">&nbsp;</div>
                        </div>
                        <div className="roi-body-container-half roi-body-container-right">
                            <div className="roi-body-container-data-half">
                                <div className="roi-body-container-label">Meter Configuration</div>
                                <div className="roi-body-container-value value-blue">&nbsp;</div>
                                <div className="roi-body-container-label">CT Configuration:</div>
                                <div className="roi-body-container-value value-purple">EM_Current: int,</div>
                            </div>
                            <div className="roi-body-container-data-half">
                                <div className="roi-body-container-label">Number Elements</div>
                                <div className="roi-body-container-value value-purple">EM_elements: "",</div>
                            </div>
                        </div>

                        <div className="roi-body-header">
                            <div className="roi-body-header-text">Reference Voltage</div>
                        </div>
                        <div className="roi-body-container-table-half">
                            <Table className="table table-sm">
                                <thead>
                                    <tr>
                                        <th>Voltage 1</th>
                                        <th>Prim.</th>
                                        <th>Sec.</th>
                                        <th>VT Serial</th>
                                        <th>Ratio</th>
                                        <th>Volts</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Phase A</td>
                                        <td className="value-orange">REF_Voltage</td>
                                        <td></td>
                                        <td className="value-orange">PT_SN</td>
                                        <td className="value-orange">PT_Ratio</td>
                                        <td className="value-green">REF_PhaseA_Voltage</td>
                                    </tr>
                                    <tr>
                                        <td>Phase B</td>
                                        <td className="value-orange">REF_Voltage</td>
                                        <td></td>
                                        <td className="value-orange">PT_SN</td>
                                        <td className="value-orange">PT_Ratio</td>
                                        <td className="value-green">REF_PhaseB_Voltage</td>
                                    </tr>
                                    <tr>
                                        <td>Phase C</td>
                                        <td className="value-orange">REF_Voltage</td>
                                        <td></td>
                                        <td className="value-orange">PT_SN</td>
                                        <td className="value-orange">PT_Ratio</td>
                                        <td className="value-green">REF_PhaseC_Voltage</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        <div className="roi-body-container-table-half roi-body-container-right">
                            <Table className="table table-sm">
                                <thead>
                                    <tr>
                                        <th>Voltage 2</th>
                                        <th>Prim.</th>
                                        <th>Sec.</th>
                                        <th>VT Serial</th>
                                        <th>Ratio</th>
                                        <th>Volts</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Phase A</td>
                                        <td className="value-orange">REF_Voltage</td>
                                        <td></td>
                                        <td className="value-orange">PT_SN_Ref_2</td>
                                        <td className="value-orange">PT_Ratio_Ref2</td>
                                        <td className="value-green">REF_PhaseA_Voltage</td>
                                    </tr>
                                    <tr>
                                        <td>Phase B</td>
                                        <td className="value-orange">REF_Voltage</td>
                                        <td></td>
                                        <td className="value-orange">PT_SN_Ref_2</td>
                                        <td className="value-orange">PT_Ratio_Ref2</td>
                                        <td className="value-green">REF_PhaseB_Voltage</td>
                                    </tr>
                                    <tr>
                                        <td>Phase C</td>
                                        <td className="value-orange">REF_Voltage</td>
                                        <td></td>
                                        <td className="value-orange">PT_SN_Ref_2</td>
                                        <td className="value-orange">PT_Ratio_Ref2</td>
                                        <td className="value-green">REF_PhaseC_Voltage</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>

                        <div className="roi-body-header">
                            <div className="roi-body-header-text">Meter Point Information</div>
                        </div>
                        <div className="roi-body-container-full">
                            Waiting On Mock Up
                        </div>

                        <div className="roi-body-header">
                            <div className="roi-body-header-text">Communications</div>
                        </div>
                        <div className="roi-body-container-full">
                            Waiting On Mock Up
                        </div>

                        <div className="roi-body-header">
                            <div className="roi-body-header-text">Notes</div>
                        </div>
                        <div className="roi-body-container-full">
                            Other_Comments
                        </div>

                        <div className="roi-body-header">
                            <div className="roi-body-header-text">Sealing Info</div>
                        </div>
                        <div className="roi-body-container-half-sealing">
                            <div className="roi-body-container-spacer">&nbsp;</div>
                            <div className="roi-body-container-label">Inspection Status:</div>
                            <div className="roi-body-container-value">&nbsp;</div>
                            <div className="roi-body-container-label-sealing">Installation Tested and Verified:</div>
                            <div className="roi-body-container-value"><Form.Check id="install1" type="checkbox" label=""/></div>
                            <div className="roi-body-container-label-sealing">Installation Tested, Corrected and Verified (see Notes):</div>
                            <div className="roi-body-container-value"><Form.Check id="install2" type="checkbox" label="" /></div>
                            <div className="roi-body-container-label-sealing">Installation Rejected:</div>
                            <div className="roi-body-container-value value-red"><Form.Check id="install3" type="checkbox" label="" /></div>
                            <div className="roi-body-container-spacer">&nbsp;</div>
                            <div className="roi-body-container-label-short">QMC Seal:</div>
                            <div className="roi-body-container-value"><div className="roi-body-container-signed">&nbsp;</div>(Red seal)</div>
                            <div className="roi-body-container-spacer">&nbsp;</div>
                        </div>
                        <div className="roi-body-container-half-sealing roi-body-container-right">
                            <div className="roi-body-container-spacer">&nbsp;</div>
                            <div className="roi-body-container-label-longer">Commissioned Date:</div>
                            <div className="roi-body-container-value">9/5/2024</div>
                            <div className="roi-body-container-label-longer">Commissioned By:</div>
                            <div className="roi-body-container-value"><div className="roi-body-container-signed">Ferson Sarmiento</div>Any tech</div>
                            <div className="roi-body-container-spacer">&nbsp;</div>
                            <div className="roi-body-container-label-longer">S-E-04 Inspection Date:</div>
                            <div className="roi-body-container-value"><div className="roi-body-container-signed">&nbsp;</div></div>
                            <div className="roi-body-container-label-longer">S-E-04 Inspection By:</div>
                            <div className="roi-body-container-value"><div className="roi-body-container-signed">&nbsp;</div>Senior Techinician</div>
                            <div className="roi-body-container-spacer">&nbsp;</div>
                            <div className="roi-body-container-label-sealing">QA Manager Signature in the footer</div>
                            <div className="roi-body-container-spacer">&nbsp;</div>
                            <div className="roi-body-container-spacer">&nbsp;</div>
                        </div>


                    </div>
                </div>


            
            </div>
        </LoadingTemplate>
    );
};

