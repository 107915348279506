import React, { useEffect, useState } from "react";
import { useAuthFetch } from './useAuthFetch';
import { observer, useLocalObservable } from 'mobx-react';
import { getApiUrl } from "./authConfig";

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import "./assets/css/modal.css";

export type InspectionPreviewDialogData = {
    id?: string,
    creatorName: string,
    completionDate: string,
    creationDate: string,
    answers: [],
    assetObject: AssetInspectionData,
    template: Template,
    duration: number
}

export type AssetInspectionData = {
    id?: string,
    serialNumber: string,
    assetType: string,
    manufacturer: string,
    model: string,
    building: Building,
}

export type Building = {
    id?: string,
    name: string,
}

export type Template = {
    id?: string,
    name: string,
}

var indent = 0;
var parentId = "";

function formatDuration(duration) {
    // Hours, minutes and seconds
    const hrs = ~~(duration / 3600);
    const mins = ~~((duration % 3600) / 60);
    const secs = ~~duration % 60;
    let ret = "";

    if (hrs > 0) {
        ret += "" + hrs + "h " + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + "m " + (secs < 10 ? "0" : "");
    ret += "" + secs + "s";

    return ret;
}

export type InspectionPreviewDialogProps = {
    isOpen?: boolean
    onSubmit?: (status: string) => void
    initialData?: InspectionPreviewDialogData
    assetData?: AssetInspectionData
}

export const InspectionPreviewDialog = observer((props: InspectionPreviewDialogProps) => {
    const authFetch = useAuthFetch();
    const [modalOpen, setModalOpen] = useState(false);

    let isOpen = props.isOpen || false;

    var assetObject = props.assetData;

    var initialData = props.initialData || {
        creatorName: "",
        completionDate: "",
        creationDate: "",
        answers: [],
        template: null,
        duration: null
    }

    const formInspectionData = useLocalObservable(() => ({
        creatorName: "",
        completionDate: "",
        creationDate: "",
        answers: [],
        template: null,
        duration: null
    }))

    const setInitialData = () => {
        formInspectionData.creatorName = initialData.creatorName;
        formInspectionData.completionDate = initialData.completionDate;
        formInspectionData.creationDate = initialData.creationDate;
        formInspectionData.answers = initialData.answers;
        formInspectionData.template = initialData.template;
        formInspectionData.duration = initialData.duration;
    }

    const checkAnswerColor = (answer) => {
        switch (answer) {
            case "N/A":
                return "#bbb";
            case "Complete":
            case "Pass":
                return "#00cc66";
            default:
                return "#3366cc";
        }
    };

    const assetTypeImage = (AssetType) => {
        switch (AssetType) {
            case "Thermal":
                return require("./assets/images/thermal-icon.png")
            case "Gas":
                return require("./assets/images/gas-icon.png")
            case "Electricity":
                return require("./assets/images/electricity-icon.png")
            case "Communication":
                return require("./assets/images/communication-icon.png")
            case "Steam":
                return require("./assets/images/steam-icon.png")
            default:
                return require("./assets/images/water-icon.png")
        }
    };

    const downloadImage = async (photo) => {
        let fetchResponse = await authFetch('GET', getApiUrl(`files/${photo}/download`)).then(response => response.blob())
            .then(blob => {
                const imageUrl = URL.createObjectURL(blob);
                if (document.getElementById('img-' + photo)) {
                    document.getElementById('img-' + photo).setAttribute('src', imageUrl);
                }

            })
            .catch(error => {
                console.error('Error downloading the file:', error);
            });
    }

    useEffect(() => { setInitialData() }, [props.initialData]);

    const handleClose = () => {
        props.onSubmit("cancel")
    };

    return (
        <Modal show={isOpen} size="xl">
            <Modal.Body>

                <div className="modalinspection">
                    <Button onClick={handleClose} type="button" className="btn btn-secondary">
                        Close
                    </Button>
                </div>

                <div className="titleRow">
                    <img style={{ width: 100, float: 'left' }} src={require("./assets/images/logo.png")}></img>
                    <div style={{ marginLeft: '20px', marginTop: '25px', fontSize: '16px', width: '170px', float: 'left' }}>
                        <span style={{ float: 'left', lineHeight: '20px' }}>573 Sherling Place</span>
                        <span style={{ float: 'left', lineHeight: '20px' }}>Port Coquitlam</span>
                        <span style={{ float: 'left', lineHeight: '20px' }}>V3B 0J6</span>
                    </div>
                </div>

                <div style={{ float: 'left', backgroundColor: "#3366cc", height: "150px", width: "100%", marginTop: "20px" }}>
                    <img style={{ width: 100, float: 'left', marginLeft: '30px', marginTop: '25px' }} src={require("./assets/images/inspection-icon.png")}></img>
                    <div style={{ color: "white", textAlign: "center", lineHeight: "150px", fontSize: "40px", fontWeight:"bold" }}>INSPECTION REPORT</div>
                </div>
 
                <div style={{ overflow: 'auto', padding: '20px 24px' }} >
                    <div style={{ color:'var(--color-primary)' }}>
                        <div style={{ float: 'left', fontSize: '22px', fontWeight: 'bold' }}>
                            <span>{assetObject == null ? "" : assetObject.manufacturer} - {assetObject == null ? "" : assetObject.model} | {assetObject == null ? "" : assetObject.serialNumber}</span>
                        </div>

                        <div style={{ float: 'right' }}>
                            <img style={{ width: 30, float: 'left', marginRight: '5px' }} src={assetObject == null ? "" : assetTypeImage(assetObject.assetType)}></img>
                            <span style={{ fontSize: '22px', fontWeight: 'bold' }}>{assetObject == null ? "" : assetObject.assetType}</span>
                        </div>
                        <div style={{clear:"both"} }></div>
                    </div>

                    <div style={{ backgroundColor: "#3366cc", height: "40px", width: "100%", marginTop: "20px" }}>
                        <div style={{ color: "white", marginLeft:'30px', lineHeight: "40px", fontSize: "14px", fontWeight: "bold" }}>OVERVIEW</div>
                    </div>

                    <div className="info-container">
                        <div className="info-item-date-container text-end">
                            <span className="info-item-label" style={{ float: 'left', marginRight:'5px' }}>Completed: </span>{new Date(formInspectionData.completionDate).toLocaleString()}<br />
                            <span className="info-item-label" style={{ float: 'left', marginRight: '5px' }}>Uploaded: </span>{new Date(formInspectionData.creationDate).toLocaleString()}<br />
                            <span className="info-item-label" style={{ float: 'left', marginRight: '5px' }}>Duration: </span>{formatDuration(formInspectionData.duration)}
                        </div>

                        <div className="info-item-container">
                            <span className="info-item-label">Procedure:</span>
                            <span> {formInspectionData.template?.name} (v{formInspectionData.template?.version})</span>
                        </div>

                        <div className="info-item-container">
                            <span className="info-item-label">Technician:</span>
                            <span> {formInspectionData.creatorName}</span>
                        </div>

                        <div className="info-item-container">
                            <span className="info-item-label">Building/Project:</span>
                            <span> {assetObject == null || assetObject.building == null ? "" : assetObject.building.name}</span>
                        </div>
                    </div>

                    <div style={{ backgroundColor: "#3366cc", height: "40px", width: "100%", float: 'left', marginTop: '30px' }}>
                        <div style={{ color: "white", marginLeft: '30px', lineHeight: "40px", fontSize: "14px", fontWeight: "bold" }}>DETAILS</div>
                    </div>
                    {
                        formInspectionData.answers?.map((answer, idx) =>
                            <>
                                {(() => {
                                    if (answer.parentQuestionId == "") {
                                        parentId = "";
                                        indent = 0;
                                        if (true) {

                                        }
                                        return (

                                            <>
                                                <hr className="qa-line" />
                                                <div className="qa-container" key={idx} style={{ marginLeft: indent } as React.CSSProperties}>
                                                    <div >
                                                        <span className="question-text">{answer.questionText == "" ? answer.questionId : answer.questionText}</span>
                                                        <span className="answer-text" style={{ color: `${checkAnswerColor(answer.response)}` }}>{answer.response}</span>
                                                    </div>
                                                    {
                                                        answer.photos != null ? answer.photos.map((photo, pidx) => {
                                                            downloadImage(photo)
                                                            return <><img id={`img-${photo}`} key="pidx" className="mb-2 me-2" style={{ maxHeight: "400px", maxWidth: "400px" }} /></>
                                                        }) : null
                                                    }
                                                </div>
                                            </>
                                        )
                                    } else {
                                        if (answer.parentQuestionId != parentId) { indent = indent + 40; }

                                        parentId = answer.parentQuestionId;
                                        return (
                                                <div className="qa-container" key={idx} style={{ marginLeft: indent } as React.CSSProperties}>
                                                    <div >
                                                        <span className="question-text">{answer.questionText == "" ? answer.questionId : answer.questionText}</span>
                                                        <span className="answer-text" style={{ color: `${checkAnswerColor(answer.response)}` }}>{answer.response}</span>
                                                    </div>
                                                    {
                                                    answer.photos != null ? answer.photos == null ? '' : answer.photos.map((photo, pidx) => {
                                                            downloadImage(photo)
                                                            return <><img id={`img-${photo}`} key="pidx" className="mb-2 me-2" style={{ maxHeight: "400px", maxWidth: "400px" }} /></>
                                                        }) : null
                                                    }
                                                </div>
                                        )

                                    }
                                })()}
                            </>
                        )}

                </div>
            </Modal.Body>

        </Modal>
    );
});
